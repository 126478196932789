import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Stack,
} from "@mui/material";

const ContactUs = () => {
  return (
    <>
      {/* Hero Section */}
      <Box
        sx={{
          color: "white",
          width: "100%",
          py: 10,
          px: 2,
          textAlign: "center",

          backgroundColor: "#333", // Emerald green background
        }}>
        <Typography variant="h3" fontWeight="bold">
          Contact Us
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ mt: 2, maxWidth: 800, mx: "auto", px: 2 }}>
          Have questions? We’re here to help. Fill out the form below, or use
          the contact information provided to get in touch.
        </Typography>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          mx: "auto",
          mt: 8,
          mb: 4,
          px: 3,
          maxWidth: 800,
        }}>
        {/* Contact Form */}
        <Grid item xs={12} md={7}>
          <Paper
            elevation={4}
            sx={{
              p: 4,
              borderRadius: 2,
              bgcolor: "background.paper",
            }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              Send Us a Message
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: "text.secondary" }}>
              We’d love to hear from you. Fill out the form below, and we’ll get
              back to you shortly.
            </Typography>
            <form>
              <Stack spacing={3}>
                <TextField label="Name" variant="outlined" fullWidth required />
                <TextField
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  required
                />
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  required
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    color: "#FFF8E1", // Light ivory text
                    "&:hover": {
                      // Darken on hover blue
                      backgroundColor: "#1565C0", // Deep blue background
                    },
                  }}>
                  Send Message
                </Button>
              </Stack>
            </form>
          </Paper>
        </Grid>
      </Box>
    </>
  );
};

export default ContactUs;
