import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Typography, Box, Button, TextField, Grid, Paper } from "@mui/material";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { user, role } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState(user?.email || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate("/");
  };

  const handleUpdateEmail = async () => {
    setLoading(true);

    // Update email in the authentication system
    const { error: authError } = await supabase.auth.update({ email });
    if (authError) {
      alert(authError.message);
      setLoading(false);
      return;
    }

    // Update email in the users table
    const { error: dbError } = await supabase
      .from("users")
      .update({ email })
      .eq("id", user.id); // Assuming 'id' is the primary key in the users table

    if (dbError) {
      alert(dbError.message);
    } else {
      alert("Email updated successfully!");
    }

    setLoading(false);
  };

  const handleUpdatePassword = async () => {
    setLoading(true);
    const { error } = await supabase.auth.update({ password });
    if (error) {
      alert(error.message);
    } else {
      alert("Password updated successfully!");
    }
    setLoading(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Profile
        </Typography>
        <Typography variant="body1" gutterBottom>
          Email: {user?.email}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Role: {role}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          sx={{ mt: 2 }}>
          Logout
        </Button>
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Update Email
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateEmail}
              disabled={loading}>
              Update Email
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Update Password
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdatePassword}
              disabled={loading}>
              Update Password
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Profile;
