import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../../supabaseClient";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import RequestTable from "../../components/Admin/RequestTable";
import ScheduleDialog from "../../components/Admin/ScheduleDialog";
import { AuthContext } from "../../context/AuthContext";

const AdminDashboard = () => {
  const { user } = useContext(AuthContext);
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [groupBy, setGroupBy] = useState("buyer");
  const [groupedRequests, setGroupedRequests] = useState({});
  const [groupedByBuyer, setGroupedByBuyer] = useState({});
  const [pickupTime, setPickupTime] = useState("");
  const [dropoffTime, setDropoffTime] = useState("");
  const [driver, setDriver] = useState("");
  const [drivers, setDrivers] = useState([]);

  // For cancellation logic
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelRequest, setCancelRequest] = useState(null);

  useEffect(() => {
    fetchRequests();
    fetchDrivers();
  }, []);

  const fetchRequests = async () => {
    try {
      const { data: requestsData, error } = await supabase
        .from("requests")
        .select(
          `
          *,
          buyer:buyer_id (
            id, 
            first_name, 
            last_name, 
            email, 
            profiles (address)
          ),
          donor:donor_id (
            id, 
            first_name, 
            last_name, 
            email, 
            profiles (address)
          ),
          items (
            id,
            title, 
            description, 
            category, 
            condition,
            stock,
            status
          ),
          cart:cart_id (
            id,
            created_at
          )
        `
        )
        .eq("status", "pending");

      if (error) {
        console.error("Error fetching requests:", error.message);
        return;
      }

      const donorIds = [...new Set(requestsData.map((r) => r.donor_id))];

      // Fetch donor availability
      const { data: donorAvailData, error: availError } = await supabase
        .from("user_availability")
        .select("*")
        .in("user_id", donorIds);

      if (availError) {
        console.error(
          "Error fetching donor availabilities:",
          availError.message
        );
      }

      // Map donor_id to availability array
      const availMap = {};
      donorAvailData?.forEach((d) => {
        if (!availMap[d.user_id]) {
          availMap[d.user_id] = [];
        }
        availMap[d.user_id].push({
          day: d.day,
          start_time: d.start_time,
          end_time: d.end_time,
        });
      });

      // Group by buyer_id
      const groupedByBuyerId = requestsData.reduce((acc, req) => {
        const buyerId = req.buyer_id;
        if (!acc[buyerId]) {
          acc[buyerId] = {
            buyerInfo: req.buyer,
            cartGroups: {},
          };
        }

        const groupingKey = req.cart_id || req.requested_at;
        if (!acc[buyerId].cartGroups[groupingKey]) {
          acc[buyerId].cartGroups[groupingKey] = {
            timestamp: req.cart?.created_at || req.requested_at,
            requests: [],
          };
        }
        acc[buyerId].cartGroups[groupingKey].requests.push(req);
        return acc;
      }, {});

      // Group by donor_id
      const groupedByDonorId = requestsData.reduce((acc, req) => {
        const donorId = req.donor_id;
        if (!acc[donorId]) {
          acc[donorId] = {
            donorInfo: req.donor,
            requests: [],
            donorAvailability: availMap[donorId] || [],
          };
        }
        acc[donorId].requests.push(req);
        return acc;
      }, {});

      setRequests(requestsData);
      setGroupedRequests(groupedByDonorId);
      setGroupedByBuyer(groupedByBuyerId);
    } catch (err) {
      console.error("Fetch Requests Error:", err.message);
    }
  };

  const fetchDrivers = async () => {
    try {
      const { data, error } = await supabase
        .from("drivers")
        .select(
          "id, first_name, last_name, vehicle_type, available_days, available_times"
        );

      if (error) {
        console.error("Error fetching drivers:", error.message);
        return;
      }

      setDrivers(data);
    } catch (err) {
      console.error("Fetch Drivers Error:", err.message);
    }
  };

  const handleSchedule = async (selectedRequestIds) => {
    try {
      if (!driver || !pickupTime || !dropoffTime) {
        alert(
          "Please fill in all required fields (driver, pickup time, and dropoff time)"
        );
        return;
      }

      const { data: requestsData, error: requestsError } = await supabase
        .from("requests")
        .select("id, item_id, buyer_id, donor_id, quantity, items (id)")
        .in("id", selectedRequestIds);

      if (requestsError) {
        console.error("Error fetching request data:", requestsError);
        throw requestsError;
      }

      if (!requestsData || requestsData.length === 0) {
        throw new Error("No request data found");
      }

      const orders = requestsData.map((request) => {
        if (
          !request.id ||
          !request.item_id ||
          !request.buyer_id ||
          !request.donor_id
        ) {
          console.error("Missing required data for request:", request);
          throw new Error("Missing required data for order creation");
        }
        return {
          request_id: request.id,
          item_id: request.item_id,
          buyer_id: request.buyer_id,
          donor_id: request.donor_id,
          admin_id: user.id,
          assigned_driver: driver,
          pickup_time: pickupTime,
          dropoff_time: dropoffTime,
          status: "scheduled",
        };
      });

      const { error: orderError } = await supabase
        .from("orders")
        .insert(orders);

      if (orderError) throw orderError;

      const { error: requestError } = await supabase
        .from("requests")
        .update({
          status: "scheduled",
          approved_by: user.id,
          approved_at: new Date().toISOString(),
        })
        .in("id", selectedRequestIds);

      if (requestError) throw requestError;

      setDialogOpen(false);
      await fetchRequests();

      // Reset form
      setPickupTime("");
      setDropoffTime("");
      setDriver("");
      setSelectedRequest(null);

      alert("Orders scheduled successfully!");
    } catch (error) {
      console.error("Error scheduling orders:", error);
      alert("Failed to schedule orders: " + error.message);
    }
  };

  const handleOpenCancelDialog = (request) => {
    setCancelRequest(request);
    setCancelDialogOpen(true);
    setCancelReason("");
  };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setCancelRequest(null);
    setCancelReason("");
  };

  const sendCancellationEmail = (buyerEmail, reason) => {
    // Placeholder function for sending email
    console.log("sendCancellationEmail called with:", buyerEmail, reason);
  };

  const handleConfirmCancel = async () => {
    if (!cancelRequest) return;

    try {
      // Fetch the latest request data to know item_id and quantity
      const { data: requestData, error: fetchError } = await supabase
        .from("requests")
        .select("id, item_id, quantity, items (id, stock, status)")
        .eq("id", cancelRequest.id)
        .single();

      if (fetchError) throw fetchError;
      if (!requestData) throw new Error("Request not found.");

      const { item_id, quantity, items } = requestData;

      // Update request status to canceled
      const { error: requestError } = await supabase
        .from("requests")
        .update({
          status: "canceled",
        })
        .eq("id", cancelRequest.id);

      if (requestError) throw requestError;

      // Update the item's stock: add back the quantity
      const newStock = items.stock + quantity;
      const updateFields = { stock: newStock };

      // If item was out_of_stock and now has stock > 0, change status to available
      if (items.status === "out_of_stock" && newStock > 0) {
        updateFields.status = "available";
      }

      const { error: itemError } = await supabase
        .from("items")
        .update(updateFields)
        .eq("id", item_id);

      if (itemError) throw itemError;

      // Send cancellation email (not implemented)
      sendCancellationEmail(cancelRequest.buyer.email, cancelReason);

      // Close dialog and refresh requests
      handleCloseCancelDialog();
      await fetchRequests();

      alert("Request canceled successfully!");
    } catch (err) {
      console.error("Error canceling request:", err.message);
      alert("Failed to cancel request: " + err.message);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        {groupBy === "buyer"
          ? "Buyer Requests Dashboard"
          : "Donor Requests Dashboard"}
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Button
          variant={groupBy === "buyer" ? "contained" : "outlined"}
          onClick={() => setGroupBy("buyer")}
          sx={{ mr: 1 }}>
          Group by Buyer
        </Button>
        <Button
          variant={groupBy === "donor" ? "contained" : "outlined"}
          onClick={() => setGroupBy("donor")}>
          Group by Donor
        </Button>
      </Box>

      <RequestTable
        groupedRequests={groupBy === "buyer" ? groupedByBuyer : groupedRequests}
        onSchedule={(request) => {
          setSelectedRequest(request);
          setDialogOpen(true);
        }}
        groupBy={groupBy}
        onCancelRequest={handleOpenCancelDialog}
      />

      <ScheduleDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setSelectedRequest(null);
          setPickupTime("");
          setDropoffTime("");
          setDriver("");
        }}
        selectedRequest={selectedRequest}
        groupedRequests={groupBy === "buyer" ? groupedByBuyer : groupedRequests}
        groupBy={groupBy}
        drivers={drivers}
        pickupTime={pickupTime}
        setPickupTime={setPickupTime}
        dropoffTime={dropoffTime}
        setDropoffTime={setDropoffTime}
        driver={driver}
        setDriver={setDriver}
        onSave={handleSchedule}
      />

      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
        <DialogTitle>Cancel Request</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for cancellation"
            multiline
            rows={4}
            fullWidth
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog}>Close</Button>
          <Button
            onClick={handleConfirmCancel}
            variant="contained"
            color="error">
            Confirm Cancellation
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminDashboard;
