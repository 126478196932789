import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { supabase } from "../../supabaseClient";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const CartPage = ({
  cart,
  setCart,
  removeFromCart,
  clearCart,
  changeCartItemQuantity,
}) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error] = useState(null);

  const handleCheckout = async () => {
    if (!user) {
      alert("Please login before checking out.");
      navigate("/login");
      return;
    }

    if (cart.length === 0) {
      alert("Your cart is empty.");
      return;
    }

    try {
      // Step 1: Verify stock before creating any requests
      const itemIds = cart.map((item) => item.item_id);
      const { data: itemsData, error: itemsError } = await supabase
        .from("items")
        .select("id, stock, title")
        .in("id", itemIds);

      if (itemsError) throw itemsError;

      const stockMap = {};
      itemsData.forEach((i) => {
        stockMap[i.id] = { stock: i.stock, title: i.title };
      });

      for (const cartItem of cart) {
        const dbItem = stockMap[cartItem.item_id];
        if (!dbItem || dbItem.stock < cartItem.quantity) {
          throw new Error(
            `Item "${cartItem.title}" is no longer available in the requested quantity.`
          );
        }
      }

      // Step 2: Create a new cart in DB
      const { data: newCart, error: cartError } = await supabase
        .from("carts")
        .insert({
          user_id: user.id,
          status: "active",
        })
        .select()
        .single();

      if (cartError) throw cartError;

      // Step 3: Create requests and update stock
      for (const cartItem of cart) {
        const { data: currentItem, error: fetchError } = await supabase
          .from("items")
          .select("stock, donor_id")
          .eq("id", cartItem.item_id)
          .single();

        if (fetchError) throw fetchError;

        if (!currentItem || currentItem.stock < cartItem.quantity) {
          throw new Error(
            `Item "${cartItem.title}" just went out of stock. Please update your cart.`
          );
        }

        const { error: requestError } = await supabase.from("requests").insert({
          item_id: cartItem.item_id,
          buyer_id: user.id,
          donor_id: currentItem.donor_id,
          status: "pending",
          quantity: cartItem.quantity,
          cart_id: newCart.id,
        });

        if (requestError) throw requestError;

        const newStock = currentItem.stock - cartItem.quantity;
        const updateFields = { stock: newStock };
        if (newStock === 0) {
          updateFields.status = "out_of_stock";
        }
        const { error: updateError } = await supabase
          .from("items")
          .update(updateFields)
          .eq("id", cartItem.item_id);

        if (updateError) throw updateError;
      }

      // Step 4: Mark the cart as completed
      const { error: updateCartError } = await supabase
        .from("carts")
        .update({ status: "completed" })
        .eq("id", newCart.id);

      if (updateCartError) throw updateCartError;

      // Step 5: Clear local cart and inform user
      clearCart();
      alert("Checkout completed successfully!");
      navigate("/browse");
    } catch (error) {
      console.error("Error during checkout:", error);
      alert(`Failed to complete checkout: ${error.message}`);
    }
  };

  // Helper to safely change quantities
  const handleChangeQuantity = (item, newQuantity) => {
    if (newQuantity < 1) return; // No zero or negative quantities
    if (newQuantity > item.stock) {
      alert(
        `Cannot exceed available stock of ${item.stock} for "${item.title}".`
      );
      return;
    }
    const difference = newQuantity - item.quantity;
    changeCartItemQuantity(item.item_id, difference);
  };

  if (error) {
    return <div>Error loading cart: {error}</div>;
  }

  if (cart.length === 0) {
    return (
      <Typography variant="h5" align="center" mt={4}>
        Your cart is empty.
      </Typography>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4, p: 2 }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Your Cart
      </Typography>
      <List>
        {cart.map((item, index) => (
          <ListItem key={index} divider>
            <ListItemText
              primary={item.title}
              secondary={`Category: ${item.category || ""}, Condition: ${
                item.condition || ""
              }, Quantity: ${item.quantity}/${item.stock} max`}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Button
                variant="outlined"
                onClick={() => handleChangeQuantity(item, item.quantity - 1)}
                disabled={item.quantity <= 1}>
                -
              </Button>
              <TextField
                type="number"
                value={item.quantity}
                onChange={(e) => {
                  const qty = parseInt(e.target.value, 10);
                  handleChangeQuantity(item, qty);
                }}
                inputProps={{ min: 1, max: item.stock }}
                size="small"
                sx={{ width: 60 }}
              />
              <Button
                variant="outlined"
                onClick={() => handleChangeQuantity(item, item.quantity + 1)}
                disabled={item.quantity >= item.stock}>
                +
              </Button>
              <IconButton
                edge="end"
                color="error"
                onClick={() => removeFromCart(item.item_id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCheckout}
        sx={{ mt: 2 }}>
        Proceed to Checkout
      </Button>
    </Box>
  );
};

export default CartPage;
