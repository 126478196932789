import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import {
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Snackbar,
  Alert,
  Card,
  CardMedia,
  IconButton,
  FormControlLabel,
  Checkbox,
  Grid,
  Stack,
} from "@mui/material";
import { Upload } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

const DAYS_OPTIONS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const AddItem = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    category: "",
    stock: 1,
    condition: "",
    location: "",
    status: "available",
  });
  const navigate = useNavigate();

  const [imageFiles, setImageFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [donorId, setDonorId] = useState(null);

  const [availability, setAvailability] = useState(
    DAYS_OPTIONS.reduce((acc, day) => {
      acc[day] = { enabled: false, start_time: "09:00", end_time: "17:00" };
      return acc;
    }, {})
  );

  useEffect(() => {
    (async () => {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) {
        console.error(sessionError);
        return;
      }
      const userId = sessionData.session?.user?.id;
      setDonorId(userId);

      if (userId) {
        // Fetch existing availability
        const { data: availabilityData, error: availabilityError } =
          await supabase
            .from("user_availability")
            .select("*")
            .eq("user_id", userId);

        if (
          !availabilityError &&
          availabilityData &&
          availabilityData.length > 0
        ) {
          const updated = { ...availability };
          availabilityData.forEach((av) => {
            if (DAYS_OPTIONS.includes(av.day)) {
              updated[av.day] = {
                enabled: true,
                start_time: av.start_time.slice(0, 5),
                end_time: av.end_time.slice(0, 5),
              };
            }
          });
          setAvailability(updated);
        }
      }
    })();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles((prev) => [...prev, ...files]);
    const urls = files.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prev) => [...prev, ...urls]);
  };

  const removeImage = (index) => {
    const updatedFiles = [...imageFiles];
    const updatedPreviews = [...previewUrls];
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
    setImageFiles(updatedFiles);
    setPreviewUrls(updatedPreviews);
  };

  const handleDayEnabledChange = (day) => {
    setAvailability((prev) => ({
      ...prev,
      [day]: { ...prev[day], enabled: !prev[day].enabled },
    }));
  };

  const handleDayTimeChange = (day, field, value) => {
    setAvailability((prev) => ({
      ...prev,
      [day]: { ...prev[day], [field]: value },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      if (imageFiles.length < 2) {
        setError("Please upload at least 2 images.");
        return;
      }

      const imageUrls = [];
      for (const imageFile of imageFiles) {
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from("item-images")
          .upload(`public/${Date.now()}-${imageFile.name}`, imageFile, {
            cacheControl: "3600",
            upsert: false,
          });

        if (uploadError) throw uploadError;

        const { data: publicUrl } = supabase.storage
          .from("item-images")
          .getPublicUrl(uploadData.path);

        imageUrls.push(publicUrl.publicUrl);
      }

      if (!donorId) {
        setError("User is not authenticated.");
        return;
      }

      const stock = parseInt(formData.stock);
      if (isNaN(stock) || stock < 0) {
        setError("Stock must be a valid number greater than or equal to 0");
        return;
      }

      // Insert item
      const { error: insertError } = await supabase.from("items").insert([
        {
          ...formData,
          stock: stock,
          status: stock > 0 ? "available" : "out_of_stock",
          image_url: JSON.stringify(imageUrls),
          donor_id: donorId,
        },
      ]);

      if (insertError) throw insertError;

      // Update availability
      const { error: deleteError } = await supabase
        .from("user_availability")
        .delete()
        .eq("user_id", donorId);

      if (deleteError) throw deleteError;

      const rowsToInsert = [];
      for (const day of DAYS_OPTIONS) {
        if (availability[day].enabled) {
          rowsToInsert.push({
            user_id: donorId,
            day: day,
            start_time: availability[day].start_time,
            end_time: availability[day].end_time,
          });
        }
      }

      if (rowsToInsert.length > 0) {
        const { error: insertAvailError } = await supabase
          .from("user_availability")
          .insert(rowsToInsert);
        if (insertAvailError) throw insertAvailError;
      }

      setSuccess(true);
      setFormData({
        title: "",
        description: "",
        category: "",
        stock: 1,
        condition: "",
        location: "",
        status: "available",
      });
      setImageFiles([]);
      setPreviewUrls([]);
      setAvailability(
        DAYS_OPTIONS.reduce((acc, day) => {
          acc[day] = { enabled: false, start_time: "09:00", end_time: "17:00" };
          return acc;
        }, {})
      );

      navigate("/donor/listings");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
        mx: "auto",
        px: { xs: 2, sm: 3 },
        py: { xs: 3, sm: 4 },
        boxSizing: "border-box",
      }}>
      <Typography variant="h5" align="center" fontWeight="bold" gutterBottom>
        Add a New Item
      </Typography>

      <Typography
        variant="body2"
        align="center"
        color="text.secondary"
        sx={{ mb: 3 }}>
        Provide clear details and images. This helps recipients understand your
        item better.
      </Typography>

      <form onSubmit={handleSubmit}>
        <Stack spacing={{ xs: 2, sm: 3 }}>
          <TextField
            label="Item Title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
            fullWidth
            variant="outlined"
            placeholder="e.g., Men's Winter Jacket (L)"
          />

          <TextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            placeholder="Brand, material, any flaws, etc."
          />

          <TextField
            label="Category"
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            select
            fullWidth
            required
            variant="outlined">
            <MenuItem value="clothing">Clothing</MenuItem>
            <MenuItem value="furniture">Furniture</MenuItem>
            <MenuItem value="electronics">Electronics</MenuItem>
            <MenuItem value="others">Others</MenuItem>
          </TextField>

          <TextField
            label="Condition"
            name="condition"
            value={formData.condition}
            onChange={handleInputChange}
            select
            fullWidth
            required
            variant="outlined">
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="used-good">Used - Good</MenuItem>
            <MenuItem value="used-fair">Used - Fair</MenuItem>
          </TextField>

          <TextField
            label="Quantity"
            name="stock"
            value={formData.stock}
            onChange={handleInputChange}
            type="number"
            fullWidth
            variant="outlined"
            placeholder="e.g., 2"
          />

          <TextField
            label="Location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            placeholder="City or general area"
          />

          {/* Availability Section */}
          <Box>
            <Typography
              variant="h6"
              sx={{ mb: 1, fontSize: { xs: "1rem", sm: "1.25rem" } }}>
              Pickup Availability
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Select which days the delivery team can come. If not available on
              a day, leave it unchecked. Preferrably Saturday and Sunday.
            </Typography>
            <Stack spacing={1}>
              {DAYS_OPTIONS.map((day) => (
                <Box
                  key={day}
                  sx={{ border: "1px solid #ddd", p: 1.5, borderRadius: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={availability[day].enabled}
                        onChange={() => handleDayEnabledChange(day)}
                        size="small"
                      />
                    }
                    label={<Typography variant="body2">{day}</Typography>}
                  />
                  {availability[day].enabled && (
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={1}
                      sx={{ mt: 1 }}>
                      <TextField
                        label="Start"
                        type="time"
                        value={availability[day].start_time}
                        onChange={(e) =>
                          handleDayTimeChange(day, "start_time", e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                        fullWidth
                        size="small"
                      />
                      <TextField
                        label="End"
                        type="time"
                        value={availability[day].end_time}
                        onChange={(e) =>
                          handleDayTimeChange(day, "end_time", e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ step: 300 }}
                        fullWidth
                        size="small"
                      />
                    </Stack>
                  )}
                </Box>
              ))}
            </Stack>
          </Box>

          {/* Image Upload */}
          <Typography variant="body2" color="text.secondary">
            Add images (tap to remove). Clear images help recipients see what
            they're getting.
          </Typography>
          <Button
            variant="outlined"
            component="label"
            startIcon={<Upload />}
            fullWidth
            sx={{ borderRadius: 2 }}>
            Upload Images
            <input
              type="file"
              hidden
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </Button>

          {previewUrls.length > 0 && (
            <Grid container spacing={1}>
              {previewUrls.map((url, index) => (
                <Grid item xs={6} key={index}>
                  <Card sx={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      image={url}
                      alt={`Preview ${index + 1}`}
                      sx={{ height: 100, objectFit: "cover" }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 4,
                        right: 4,
                        backgroundColor: "rgba(255,255,255,0.7)",
                        padding: "4px",
                      }}
                      onClick={() => removeImage(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{
              borderRadius: 2,
              py: { xs: 1, sm: 1.5 },
              mt: { xs: 2, sm: 3 },
            }}>
            Submit Item
          </Button>
        </Stack>
      </form>

      {/* Snackbar Notifications */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Item added successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddItem;
