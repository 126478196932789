import React, { createContext, useContext, useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { CircularProgress } from "@mui/material";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      setLoading(true);
      try {
        const { data: sessionData } = await supabase.auth.getSession();
        if (sessionData?.session) {
          setSession(sessionData.session);
          setUser(sessionData.session.user);

          const { data: userInfo, error } = await supabase
            .from("users")
            .select("role")
            .eq("id", sessionData.session.user.id)
            .single();

          if (error) {
            console.error("Error fetching user role:", error.message);
          } else {
            setRole(userInfo?.role ?? null);
          }
        } else {
          setSession(null);
          setUser(null);
          setRole(null);
        }
      } catch (error) {
        console.error("Error fetching session:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSession(); // Make sure this is actually invoked

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      setUser(session?.user ?? null);

      if (session) {
        fetchSession(); // Re-fetch only when session is valid
      } else {
        setRole(null);
        setLoading(false);
      }
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, []);


  if (loading) {
    // show a circular loader while the session is being fetched and center it
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ session, user, role }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

// Hook to use AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
