import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  Chip,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const DonorListings = () => {
  const [listings, setListings] = useState([]);
  const [itemStates, setItemStates] = useState({}); // { itemId: {pending, scheduled, in_delivery, completed}, ...}
  const [selectedItem, setSelectedItem] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const fetchListings = async () => {
    try {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const userId = sessionData.session?.user?.id;
      if (!userId) {
        throw new Error("User is not authenticated.");
      }

      const { data, error: itemsError } = await supabase
        .from("items")
        .select("*")
        .eq("donor_id", userId);

      if (itemsError) throw itemsError;

      setListings(data);
      // After fetching items, fetch their states
      fetchItemStates(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchItemStates = async (items) => {
    try {
      // For all items, we want to get counts from requests and orders
      // We'll do this in a loop for simplicity. If performance is needed, consider batching queries.
      const newItemStates = {};
      for (const item of items) {
        const itemId = item.id;

        // Count pending requests
        const { count: pendingCount, error: pendingError } = await supabase
          .from("requests")
          .select("id", { count: "exact", head: true })
          .eq("item_id", itemId)
          .eq("status", "pending");

        if (pendingError) throw pendingError;

        // Count orders by status
        const statuses = ["scheduled", "in_delivery", "completed"];
        const stateCounts = { scheduled: 0, in_delivery: 0, completed: 0 };

        for (const status of statuses) {
          const { count, error } = await supabase
            .from("orders")
            .select("id", { count: "exact", head: true })
            .eq("item_id", itemId)
            .eq("status", status);

          if (error) throw error;
          stateCounts[status] = count || 0;
        }

        const pending = pendingCount || 0;
        const scheduled = stateCounts.scheduled;
        const in_delivery = stateCounts.in_delivery;
        const completed = stateCounts.completed;

        const totalAssigned = pending + scheduled + in_delivery + completed;
        const available = item.stock - totalAssigned;

        newItemStates[itemId] = {
          available,
          pending,
          scheduled,
          in_delivery,
          completed,
        };
      }

      setItemStates(newItemStates);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchListings();
  }, []);

  const handleDelete = async (id) => {
    try {
      const { error } = await supabase.from("items").delete().eq("id", id);

      if (error) throw error;

      setListings((prev) => prev.filter((item) => item.id !== id));
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setOpenEditDialog(true);
  };

  const handleUpdate = async () => {
    try {
      const {
        id,
        title,
        description,
        category,
        size,
        condition,
        location,
        status,
        stock,
      } = selectedItem;

      const { error } = await supabase
        .from("items")
        .update({
          title,
          description,
          category,
          size,
          condition,
          location,
          status,
          stock,
        })
        .eq("id", id);

      if (error) throw error;

      setListings((prev) =>
        prev.map((item) =>
          item.id === id ? { ...item, ...selectedItem } : item
        )
      );
      setOpenEditDialog(false);
      setSuccess(true);

      // Re-fetch states for updated item if needed
      const updatedItem = listings.find((item) => item.id === id);
      if (updatedItem) {
        fetchItemStates([updatedItem]);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClose = () => {
    setOpenEditDialog(false);
    setSelectedItem(null);
  };

  const parseImageUrl = (imageUrl) => {
    try {
      const urls = JSON.parse(imageUrl);
      return urls[0];
    } catch {
      return "/placeholder.jpg";
    }
  };

  const renderStates = (itemId) => {
    const states = itemStates[itemId];
    if (!states) return <Typography variant="body2">Loading...</Typography>;

    const { available, pending, scheduled, in_delivery, completed } = states;
    const chips = [];

    if (available > 0) chips.push(`Available(${available})`);
    if (pending > 0) chips.push(`Pending(${pending})`);
    if (scheduled > 0) chips.push(`Scheduled(${scheduled})`);
    if (in_delivery > 0) chips.push(`In Delivery(${in_delivery})`);
    if (completed > 0) chips.push(`Completed(${completed})`);

    if (chips.length === 0) {
      // If no states are found, it might be all available or out_of_stock
      // If the stock is 0 and no counts, it's probably out of stock
      return (
        <Typography variant="body2">
          No units assigned, all available or out of stock
        </Typography>
      );
    }

    return (
      <Stack direction="row" spacing={1} sx={{ mt: 2, flexWrap: "wrap" }}>
        {chips.map((chip, idx) => (
          <Chip key={idx} label={chip} />
        ))}
      </Stack>
    );
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", mt: 4, p: 3 }}>
      <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
        My Listings
      </Typography>

      <Grid container spacing={3}>
        {listings.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={parseImageUrl(item.image_url)}
                alt={item.title}
              />
              <CardContent>
                <Typography variant="h6">{item.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Category: {item.category}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Condition: {item.condition}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Stock: {item.stock}
                </Typography>
                {/* Render the states for the item */}
                {renderStates(item.id)}
              </CardContent>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => handleEdit(item)}>
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleDelete(item.id)}>
                  Delete
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Edit Dialog */}
      {selectedItem && (
        <Dialog open={openEditDialog} onClose={handleClose}>
          <DialogTitle>Edit Item</DialogTitle>
          <DialogContent>
            <TextField
              label="Title"
              name="title"
              value={selectedItem.title}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, title: e.target.value })
              }
              fullWidth
              margin="dense"
              variant="outlined"
            />
            <TextField
              label="Description"
              name="description"
              value={selectedItem.description}
              onChange={(e) =>
                setSelectedItem({
                  ...selectedItem,
                  description: e.target.value,
                })
              }
              multiline
              rows={4}
              fullWidth
              margin="dense"
              variant="outlined"
            />
            <TextField
              label="Category"
              name="category"
              value={selectedItem.category}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, category: e.target.value })
              }
              select
              fullWidth
              margin="dense"
              variant="outlined">
              <MenuItem value="clothing">Clothing</MenuItem>
              <MenuItem value="furniture">Furniture</MenuItem>
              <MenuItem value="electronics">Electronics</MenuItem>
              <MenuItem value="others">Others</MenuItem>
            </TextField>
            <TextField
              label="Condition"
              name="condition"
              value={selectedItem.condition}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, condition: e.target.value })
              }
              select
              fullWidth
              margin="dense"
              variant="outlined">
              <MenuItem value="new">New</MenuItem>
              <MenuItem value="used-good">Used - Good</MenuItem>
              <MenuItem value="used-fair">Used - Fair</MenuItem>
            </TextField>
            <TextField
              label="Location"
              name="location"
              value={selectedItem.location}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, location: e.target.value })
              }
              fullWidth
              margin="dense"
              variant="outlined"
            />
            <TextField
              label="Status"
              name="status"
              value={selectedItem.status}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, status: e.target.value })
              }
              select
              fullWidth
              margin="dense"
              variant="outlined">
              <MenuItem value="available">Available</MenuItem>
              <MenuItem value="requested">Requested</MenuItem>
              <MenuItem value="delivered">Delivered</MenuItem>
              <MenuItem value="out_of_stock">Out of Stock</MenuItem>
            </TextField>
            <TextField
              label="Stock"
              name="stock"
              type="number"
              value={selectedItem.stock}
              onChange={(e) =>
                setSelectedItem({
                  ...selectedItem,
                  stock: parseInt(e.target.value),
                  status:
                    parseInt(e.target.value) > 0 &&
                      selectedItem.status === "out_of_stock"
                      ? "available"
                      : selectedItem.status,
                })
              }
              fullWidth
              margin="dense"
              variant="outlined"
              InputProps={{ inputProps: { min: 0 } }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Snackbar Notifications */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Action completed successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DonorListings;
