import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Chip,
} from "@mui/material";

const ItemCard = ({ item, onViewDetails }) => {
  const parseImageUrl = (imageUrl) => {
    try {
      const urls = JSON.parse(imageUrl);
      return urls[0];
    } catch {
      return "/placeholder.jpg"; // Fallback
    }
  };

  const image = parseImageUrl(item.image_url);
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: 2,
        overflow: "hidden",
        backgroundColor: "", // Light ivory background
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.02)",
          boxShadow: 6,
        },
      }}>
      <Box sx={{ position: "relative" }}>
        <CardMedia
          component="img"
          image={image}
          alt={item.title}
          sx={{
            width: "100%",
            height: "auto",
            maxHeight: "250px", // Ensures image does not overflow
            objectFit: "cover",
          }}
        />
      </Box>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Typography
          variant="h6"
          color="#37474F" // Dark slate grey for title
          gutterBottom
          sx={{ fontWeight: "bold" }}>
          {item.title}
        </Typography>
        <Chip
          label={item.category}
          sx={{
            marginBottom: 1,
            backgroundColor: "#333", // Golden yellow background
            color: "#FFF8E1", // Light ivory text for contrast
            fontSize: "0.75rem",
            fontWeight: "medium",
            borderRadius: "16px", // Oval shape
          }}
        />

        <Typography
          variant="body2"
          color="#5D4037" // Muted brown for description text
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
          }}>
          {item.description}
        </Typography>
        <Box
          sx={{
            mt: "auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pt: 2,
          }}>
          <Typography variant="body2" fontWeight="medium" color="#1565C0">
            {/* Deep blue for stock count */}
            {item.stock} Available
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={() => onViewDetails(item.id)}
            sx={{
              color: "#FFF8E1", // Light ivory text
              "&:hover": {
                backgroundColor: "#1565C0", // Deep blue background
              },
            }}>
            View Details
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ItemCard;
