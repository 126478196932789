import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/Contact";
import LoginPage from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ProtectedRoute from "./ProtectedRoute";
import Browse from "./pages/receiptient/Browse";
import Profile from "./pages/Profile";
import AuthProvider from "./context/AuthContext";
import AddItem from "./pages/donor/AddItem";
import DonorListings from "./pages/donor/DonorListings";
import Navbar from "./components/Navbar";
import { Footer } from "./components/Footer";
import CartPage from "./pages/receiptient/Cart";
import ItemDetails from "./pages/receiptient/ItemDetails";
import AdminDashboard from "./pages/admin/AdminDashboard";
import HistoryPage from "./pages/admin/History";
import AdminUserManagement from "./pages/admin/UserManagement";
import DriverManagement from "./pages/admin/DriverManagment";
import OrderManagement from "./pages/admin/OrderManagment";
import AdminListings from "./pages/admin/AdminListings";
import { Box } from "@mui/system";

// export default App;
function App() {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const addToCart = (itemDetails) => {
    // Check if item is already in cart
    const existingItemIndex = cart.findIndex(
      (ci) => ci.item_id === itemDetails.item_id
    );
    if (existingItemIndex > -1) {
      // If already in cart, just increment quantity
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += itemDetails.quantity || 1;
      setCart(updatedCart);
    } else {
      // Add new item to cart
      setCart((prev) => [
        ...prev,
        {
          ...itemDetails,
          quantity: itemDetails.quantity || 1,
        },
      ]);
    }
  };

  const changeCartItemQuantity = (itemId, delta) => {
    const updatedCart = cart
      .map((item) => {
        if (item.item_id === itemId) {
          return {
            ...item,
            quantity: item.quantity + delta,
          };
        }
        return item;
      })
      .filter((item) => item.quantity > 0);

    setCart(updatedCart);
  };

  const removeFromCart = (itemId) => {
    // Remove all quantities of that item from cart
    setCart((prevCart) => prevCart.filter((item) => item.item_id !== itemId));
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <Box
          sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <Navbar
            cartCount={cart.reduce((acc, item) => acc + item.quantity, 0)}
          />
          <Box sx={{ flex: 1 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route
                path="/browse"
                element={
                  <ProtectedRoute allowedRoles={["admin", "buyer"]}>
                    <Browse />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add-item"
                element={
                  <ProtectedRoute allowedRoles={["donor"]}>
                    <AddItem />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/donor/listings"
                element={
                  <ProtectedRoute allowedRoles={["donor"]}>
                    <DonorListings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/cart"
                element={
                  <ProtectedRoute allowedRoles={["buyer"]}>
                    <CartPage
                      cart={cart}
                      setCart={setCart}
                      removeFromCart={removeFromCart}
                      clearCart={clearCart}
                      changeCartItemQuantity={changeCartItemQuantity}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/item/:id"
                element={
                  <ProtectedRoute allowedRoles={["buyer"]}>
                    <ItemDetails addToCart={addToCart} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/dashboard"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/history"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <HistoryPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/users"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <AdminUserManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/drivers"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <DriverManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/orders"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <OrderManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/listings"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <AdminListings />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </AuthProvider>
    </BrowserRouter>);
}

export default App;
