import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import {
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Card,
  Grid,
  Alert,
  Snackbar,
} from "@mui/material";

const Signup = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    preferredContactMethod: "email",
    role: "buyer",
  });
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const isFormValid = () => {
    return Object.values(formData).every((value) => value || value === false);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError("");
    setShowError(false);

    if (!isFormValid()) {
      setError("Please fill in all fields.");
      setShowError(true);
      return;
    }

    const {
      email,
      password,
      firstName,
      lastName,
      phone,
      street,
      city,
      state,
      zip,
      preferredContactMethod,
      role,
    } = formData;
    const address = `${street}, ${city}, ${state}, ${zip}`;

    try {
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
      });

      if (authError) throw authError;

      const userId = authData.user.id;

      const { error: userError } = await supabase.from("users").insert([
        {
          id: userId,
          email,
          first_name: firstName,
          last_name: lastName,
          phone,
          role,
          address,
          preferred_contact_method: preferredContactMethod,
        },
      ]);

      if (userError) throw userError;

      const { error: profileError } = await supabase.from("profiles").insert([
        {
          id: userId,
          preferred_contact_method: preferredContactMethod,
          address,
        },
      ]);

      if (profileError) throw profileError;

      alert(
        "Signup successful! Please check your email to confirm your account."
      );
      navigate("/");
    } catch (error) {
      setError(error.message);
      setShowError(true);
    }
  };

  const handleClose = () => {
    setShowError(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f5f5f5">
      <Card sx={{ width: 500, padding: 4, boxShadow: 3 }}>
        <Typography variant="h4" gutterBottom align="center">
          Sign Up
        </Typography>
        <form onSubmit={handleSignup}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Address</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Street"
                variant="outlined"
                fullWidth
                name="street"
                value={formData.street}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="State"
                variant="outlined"
                fullWidth
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Postal Code"
                variant="outlined"
                fullWidth
                name="zip"
                value={formData.zip}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Preferred Contact Method"
                variant="outlined"
                fullWidth
                name="preferredContactMethod"
                value={formData.preferredContactMethod}
                onChange={handleInputChange}>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Role"
                variant="outlined"
                fullWidth
                name="role"
                value={formData.role}
                onChange={handleInputChange}>
                <MenuItem value="donor">Donor</MenuItem>
                <MenuItem value="buyer">Buyer</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}>
            Sign Up
          </Button>
        </form>
      </Card>
      <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Signup;
