import React, { useContext } from "react";
import { Box, Button, Typography, Container } from "@mui/material";
import heroImage from "../../resources/hero.jpg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export function HeroSection() {
  const { user, role } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (!user) {
      navigate("/login");
    } else if (role === "buyer") {
      navigate("/browse");
    } else if (role === "donor") {
      navigate("/donor/listings");
    } else if (role === "admin") {
      navigate("/admin/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <Box
      sx={{
        // Reduced vertical padding for mobile, ensuring CTA is more visible
        py: { xs: 10, sm: 20 },
        backgroundImage: `url(${heroImage})`,
        backgroundSize: "cover",
        backgroundPosition: { xs: "center bottom", sm: "center" },
        textAlign: "center",
        color: "white",
        position: "relative",
      }}>
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          background: "rgba(0, 0, 0, 0.5)",
        }}
      />
      <Container maxWidth="md" sx={{ position: "relative", zIndex: 1 }}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: "bold",
            // Slightly smaller font on mobile
            fontSize: { xs: "1.5rem", sm: "2.5rem" },
            lineHeight: 1.3,
          }}>
          Give a New Life to Your Unused Items
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 4,
            fontSize: { xs: "1rem", sm: "1.2rem" },
            lineHeight: 1.5,
            px: { xs: 2, sm: 0 },
          }}>
          Connect with families in need by donating clothes, furniture, and
          more.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            px: { xs: 3, sm: 4 },
            py: 1,
            fontSize: { xs: "0.9rem", sm: "1rem" },
            textTransform: "none",
            width: { xs: "100%", sm: "auto" }, // Make button full-width on mobile
          }}
          onClick={handleButtonClick}>
          Browse Items
        </Button>
      </Container>
    </Box>
  );
}
