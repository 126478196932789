import { useAuth } from "./context/AuthContext";
import { Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { user, role, session } = useAuth();

  if (!session) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!user || (allowedRoles && !allowedRoles.includes(role))) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
