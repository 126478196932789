import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";

export function HowItWorksSection() {
  const steps = [
    {
      number: "1",
      title: "Browse Items",
      description:
        "Explore available items donated by generous community members.",
    },
    {
      number: "2",
      title: "Request Items",
      description: "Add items to your cart and submit your request.",
    },
    {
      number: "3",
      title: "Get Delivery",
      description:
        "Once approved, items will be delivered to you free of charge.",
    },
  ];

  return (
    <Box sx={{ py: { xs: 4, sm: 6 }, backgroundColor: "white" }}>
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontWeight: "bold",
          color: "#1f2937",
          mb: 4,
          fontSize: { xs: "1.5rem", sm: "2rem" },
        }}>
        How It Works
      </Typography>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        sx={{ px: { xs: 2, sm: 0 } }}>
        {steps.map((step, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                textAlign: "center",
                boxShadow: 2,
                py: { xs: 3, sm: 4 },
                borderRadius: "12px",
                backgroundColor: "white",
                m: { xs: 1, sm: 2 },
              }}>
              <Avatar
                sx={{
                  width: 64,
                  height: 64,
                  backgroundColor: "#333",
                  color: "white",
                  fontSize: "24px",
                  fontWeight: "bold",
                  mx: "auto",
                  mb: 2,
                }}>
                {step.number}
              </Avatar>
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                    fontSize: { xs: "1rem", sm: "1.25rem" },
                  }}>
                  {step.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                    lineHeight: 1.4,
                  }}>
                  {step.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
