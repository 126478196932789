import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  Pagination,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ItemCard from "../../components/buyer/ItemCard";
import { useNavigate } from "react-router-dom";

const ITEMS_PER_PAGE = 12;

const BrowsePage = () => {
  const [listings, setListings] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [conditionFilter, setConditionFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchListings = async () => {
    try {
      const { data, error } = await supabase
        .from("items")
        .select("*")
        .eq("status", "available")
        .gt("stock", 0);

      if (error) throw error;
      setListings(data);
      setFilteredItems(data);
      paginate(data, 1);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    // check if user is authenticated
    // const session = supabase.auth.session();
    // if (!session) {
    //   navigate("/login");
    // }

    fetchListings();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchTerm, categoryFilter, conditionFilter, listings]);

  const applyFilters = () => {
    let filtered = listings;

    const term = searchTerm.toLowerCase().trim();
    if (term) {
      filtered = filtered.filter(
        (item) =>
          item.title.toLowerCase().includes(term) ||
          item.description.toLowerCase().includes(term)
      );
    }

    if (categoryFilter) {
      filtered = filtered.filter((item) => item.category === categoryFilter);
    }

    if (conditionFilter) {
      filtered = filtered.filter((item) => item.condition === conditionFilter);
    }

    setFilteredItems(filtered);
    paginate(filtered, 1);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  const handleConditionChange = (e) => {
    setConditionFilter(e.target.value);
  };

  const paginate = (items, pageNumber) => {
    const startIndex = (pageNumber - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setDisplayedItems(items.slice(startIndex, endIndex));
    setCurrentPage(pageNumber);
  };

  const handlePageChange = (event, value) => {
    paginate(filteredItems, value);
  };

  const handleViewDetails = (id) => {
    navigate(`/item/${id}`);
  };

  return (
    <Box sx={{ mx: "auto", mt: 4, p: { xs: 2, sm: 3 }, m: { xs: 0, sm: 2 } }}>
      <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
        Browse Listings
      </Typography>

      {/* Search and Filter Options */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          alignItems: "stretch",
          mb: 3,
          gap: 2,
        }}>
        <TextField
          label="Search items..."
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel>Category</InputLabel>
          <Select
            value={categoryFilter}
            onChange={handleCategoryChange}
            label="Category">
            <MenuItem value="">All Categories</MenuItem>
            <MenuItem value="clothing">Clothing</MenuItem>
            <MenuItem value="furniture">Furniture</MenuItem>
            <MenuItem value="electronics">Electronics</MenuItem>
            <MenuItem value="others">Others</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Condition</InputLabel>
          <Select
            value={conditionFilter}
            onChange={handleConditionChange}
            label="Condition">
            <MenuItem value="">All Conditions</MenuItem>
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="used-good">Used - Good</MenuItem>
            <MenuItem value="used-fair">Used - Fair</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Listings Grid */}
      <Grid container spacing={2}>
        {displayedItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <ItemCard item={item} onViewDetails={handleViewDetails} />
          </Grid>
        ))}
      </Grid>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 2 }}>
        <Pagination
          count={Math.ceil(filteredItems.length / ITEMS_PER_PAGE)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size={isMobile ? "small" : "medium"}
        />
      </Box>

      {/* Snackbar Notifications */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BrowsePage;
