import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import { supabase } from "../../supabaseClient";

const ORDER_STATUSES = {
  SCHEDULED: "scheduled",
  IN_DELIVERY: "in_delivery",
  DELIVERED: "delivered",
  COMPLETED: "completed",
};

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const { data, error } = await supabase.from("orders").select(`
        *,
        items (title, category),
        buyer:buyer_id (first_name, last_name, profiles (address)),
        donor:donor_id (first_name, last_name, profiles (address)),
        drivers:assigned_driver (first_name, last_name)
      `);

      if (error) throw error;

      setOrders(data);
    } catch (err) {
      console.error("Error fetching orders:", err.message);
    }
  };

  const handleStatusUpdate = async (orderId, newStatus) => {
    try {
      // Validate the status is one of the allowed values
      if (!Object.values(ORDER_STATUSES).includes(newStatus)) {
        throw new Error(
          `Invalid status: ${newStatus}. Must be one of: ${Object.values(
            ORDER_STATUSES
          ).join(", ")}`
        );
      }

      // Update the order status
      const { data: updatedOrder, error: orderError } = await supabase
        .from("orders")
        .update({ status: newStatus })
        .eq("id", orderId)
        .select();

      if (orderError) {
        console.error("Error updating order:", orderError);
        throw orderError;
      }

      // If the order status is updated successfully, also update the request status
      const { data: order } = await supabase
        .from("orders")
        .select("request_id")
        .eq("id", orderId)
        .single();

      if (order) {
        const { error: requestError } = await supabase
          .from("requests")
          .update({ status: newStatus })
          .eq("id", order.request_id);

        if (requestError) {
          console.error("Error updating request:", requestError);
          throw requestError;
        }
      }

      // Refresh the orders list
      fetchOrders();

      alert("Status updated successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to update status: " + error.message);
    }
  };

  // Update your status options in the UI
  const renderStatusSelect = (order) => (
    <Select
      value={order.status}
      onChange={(e) => handleStatusUpdate(order.id, e.target.value)}
      size="small">
      <MenuItem value={ORDER_STATUSES.SCHEDULED}>Scheduled</MenuItem>
      <MenuItem value={ORDER_STATUSES.IN_DELIVERY}>In Delivery</MenuItem>
      <MenuItem value={ORDER_STATUSES.DELIVERED}>Delivered</MenuItem>
      <MenuItem value={ORDER_STATUSES.COMPLETED}>Completed</MenuItem>
    </Select>
  );

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Order Management
      </Typography>
      {orders.length === 0 ? (
        <Typography>No orders found.</Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Buyer</TableCell>
              <TableCell>Donor</TableCell>
              <TableCell>Pickup Location</TableCell>
              <TableCell>Dropoff Location</TableCell>
              <TableCell>Driver</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>
                  {order.items?.title || "Unknown"} -{" "}
                  {order.items?.category || "Unknown"}
                </TableCell>
                <TableCell>
                  {order.buyer?.first_name} {order.buyer?.last_name}
                </TableCell>
                <TableCell>
                  {order.donor?.first_name} {order.donor?.last_name}
                </TableCell>
                <TableCell>
                  {order.donor?.profiles?.address || "Unknown location"}
                </TableCell>
                <TableCell>
                  {order.buyer?.profiles?.address || "Unknown location"}
                </TableCell>
                <TableCell>
                  {order.drivers?.first_name || "Unassigned"}{" "}
                  {order.drivers?.last_name}
                </TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>{renderStatusSelect(order)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {/* Snackbar Notifications */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OrderManagement;
