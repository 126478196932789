import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
} from "@mui/material";
import { supabase } from "../../supabaseClient";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export function FeaturedItemsSection() {
  const [items, setItems] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleButtonClick = (id) => {
    if (user) {
      navigate("/item/" + id);
    } else {
      navigate("/login");
    }
  };

  const handleViewAllClick = () => {
    if (user) {
      navigate("/browse");
    } else {
      navigate("/login");
    }
  };

  // Fetch items from Supabase
  const fetchItems = async () => {
    try {
      const { data, error } = await supabase
        .from("items")
        .select("*")
        .order("created_at", { ascending: false })
        .limit(2);
      if (error) throw error;

      const uniqueItems = Array.from(
        new Map(data.map((item) => [item.id, item])).values()
      );
      setItems(uniqueItems);
    } catch (error) {
      console.error("Error fetching featured items:", error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Box sx={{ py: { xs: 4, sm: 6 }, backgroundColor: "#f9f9f9" }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontWeight: "bold",
          mb: 4,
          fontSize: { xs: "1.5rem", sm: "2rem" },
        }}>
        Featured Items
      </Typography>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        sx={{ px: { xs: 2, sm: 0 } }}>
        {items.map((item) => {
          let imageUrl = "/images/placeholder.jpg";
          try {
            const parsedUrls = JSON.parse(item.image_url);
            if (Array.isArray(parsedUrls) && parsedUrls.length > 0) {
              imageUrl = parsedUrls[0];
            }
          } catch (error) {
            console.error("Error parsing image_url:", error);
          }

          return (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <Card
                sx={{
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}>
                <CardMedia
                  component="img"
                  // Slightly smaller image on mobile, ensure objectFit cover
                  sx={{
                    height: { xs: 150, sm: 200 },
                    objectFit: "cover",
                  }}
                  image={imageUrl}
                  alt={item.title}
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    px: { xs: 2, sm: 3 },
                  }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "1rem", sm: "1.25rem" },
                    }}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      mb: 2,
                      maxHeight: "3.6em",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      lineHeight: 1.4,
                    }}>
                    {item.description || "No description available."}
                  </Typography>
                  <Box mt="auto">
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleButtonClick(item.id)}
                      sx={{
                        color: "#FFF8E1",
                        "&:hover": {
                          backgroundColor: "#1565C0",
                        },
                        fontSize: { xs: "0.9rem", sm: "1rem" },
                        py: 1,
                      }}>
                      View Details
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Box textAlign="center" sx={{ mt: 4, px: { xs: 2, sm: 0 } }}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          sx={{
            width: { xs: "100%", sm: "auto" },
            fontSize: { xs: "0.9rem", sm: "1rem" },
            py: 1,
          }}
          onClick={() => handleViewAllClick()}>
          View All Items
        </Button>
      </Box>
    </Box>
  );
}
