import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { AuthContext } from "../../context/AuthContext";
import ImageModal from "../../components/buyer/ImageModal";

import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Snackbar,
  Alert,
  Grid,
  TextField,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Divider,
  IconButton,
} from "@mui/material";
import { AddShoppingCart, Remove, Add } from "@mui/icons-material";

const ItemDetails = ({ addToCart }) => {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchItemDetails = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from("items")
        .select("*")
        .eq("id", id)
        .single();

      if (error) throw error;
      setItem(data);

      const images = parseImageUrls(data.image_url);
      if (images.length > 0) {
        setSelectedImage(images[0]);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const parseImageUrls = (imageUrl) => {
    try {
      const urls = JSON.parse(imageUrl);
      return Array.isArray(urls) ? urls : ["/placeholder.jpg"];
    } catch {
      return ["/placeholder.jpg"];
    }
  };

  useEffect(() => {
    fetchItemDetails();
  }, [id]);

  const handleGoToCart = () => {
    navigate("/cart");
  };

  const handleAddToCart = () => {
    if (!user) {
      alert("Please log in to add items to cart");
      navigate("/login");
      return;
    }

    if (!item || item.stock < quantity) {
      alert("Not enough stock.");
      return;
    }

    addToCart({
      item_id: item.id,
      donor_id: item.donor_id,
      title: item.title,
      description: item.description,
      category: item.category,
      condition: item.condition,
      image_url: item.image_url,
      quantity: quantity,
      stock: item.stock,
    });

    setSuccess(true);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!item) {
    return (
      <Typography variant="h5" sx={{ textAlign: "center", mt: 4 }}>
        Item not found.
      </Typography>
    );
  }

  const images = parseImageUrls(item.image_url);

  return (
    <Box sx={{ maxWidth: 1000, mx: "auto", mt: 4, p: { xs: 2, sm: 3, md: 4 } }}>
      <Card
        elevation={3}
        sx={{
          overflow: "visible", // Prevent content clipping
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stack on mobile, row for larger screens
          width: "100%",
        }}>
        <Grid container>
          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              height={isMobile ? "300" : "400"}
              image={selectedImage}
              alt={item.title}
              sx={{
                objectFit: "contain",
                cursor: "pointer",
              }}
              onClick={() => setOpenModal(true)} // Open modal when clicking the image
            />

            <ImageModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              image={selectedImage}
              alt={item.title}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                px: { xs: 1, sm: 2 },
              }}>
              <Grid container spacing={1} justifyContent="center">
                {images.map((url, index) => (
                  <Grid item xs={3} sm={2} key={index}>
                    <CardMedia
                      component="img"
                      height="60"
                      image={url}
                      alt={`Thumbnail ${index + 1}`}
                      sx={{
                        objectFit: "cover",
                        cursor: "pointer",
                        border:
                          selectedImage === url
                            ? `2px solid ${theme.palette.primary.main}`
                            : "none",
                        borderRadius: 1,
                      }}
                      onClick={() => setSelectedImage(url)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          {/* Details Section */}
          <Grid item xs={12} md={6}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between", // Push button to the bottom
                p: { xs: 2, sm: 3 },
                height: "auto", // Allow content to define height
              }}>
              <Typography
                variant={isMobile ? "h5" : "h4"}
                fontWeight="bold"
                gutterBottom>
                {item.title}
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                {item.description}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Category:</strong> {item.category}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Condition:</strong> {item.condition}
                </Typography>
                <Typography
                  variant="body2"
                  color={item.stock > 0 ? "success.main" : "error.main"}
                  sx={{ mb: 1 }}>
                  <strong>Availability:</strong>{" "}
                  {item.stock > 0
                    ? `${item.stock} items available`
                    : "Currently out of stock"}
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 4,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}>
                {/* Quantity Selector */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography variant="subtitle1">Quantity:</Typography>
                  <IconButton
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                    disabled={quantity <= 1}
                    size="medium">
                    <Remove fontSize="small" />
                  </IconButton>
                  <TextField
                    type="number"
                    value={quantity}
                    onChange={(e) => {
                      const val = parseInt(e.target.value, 10);
                      if (val > 0 && val <= item.stock) {
                        setQuantity(val);
                      }
                    }}
                    InputProps={{
                      inputProps: { min: 1, max: item.stock },
                      sx: { width: 60, textAlign: "center" },
                    }}
                    size="small"
                  />
                  <IconButton
                    onClick={() =>
                      setQuantity(Math.min(item.stock, quantity + 1))
                    }
                    disabled={quantity >= item.stock}
                    size="small">
                    <Add fontSize="small" />
                  </IconButton>
                </Box>
                {/* Add to Cart Button */}
                {item.stock > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleAddToCart}
                    startIcon={<AddShoppingCart />}
                    size="large">
                    Add to Cart
                  </Button>
                ) : (
                  <Button variant="contained" color="error" fullWidth disabled>
                    Out of Stock
                  </Button>
                )}
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Card>

      {/* Snackbar Notifications */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{
          vertical: isMobile ? "top" : "bottom",
          horizontal: "center",
        }}>
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          action={
            <Button
              color="inherit"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
              }}
              size="small"
              onClick={handleGoToCart}>
              Go to Cart
            </Button>
          }>
          Item added to cart!
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}
        anchorOrigin={{
          vertical: isMobile ? "top" : "bottom",
          horizontal: "center",
        }}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ItemDetails;
