import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  Stack,
} from "@mui/material";

const RequestTable = ({
  groupedRequests,
  onSchedule,
  groupBy,
  onCancelRequest,
}) => {
  const renderDonorAvailability = (availabilityArray) => {
    if (!availabilityArray || availabilityArray.length === 0) {
      return (
        <Typography variant="body2" sx={{ mt: 1 }}>
          No availability specified
        </Typography>
      );
    }
    return (
      <Box sx={{ mt: 1 }}>
        <Typography variant="subtitle2" color="text.secondary">
          Donor Availability:
        </Typography>
        {availabilityArray.map((av, index) => (
          <Typography key={index} variant="body2">
            {av.day}: {av.start_time.slice(0, 5)} - {av.end_time.slice(0, 5)}
          </Typography>
        ))}
      </Box>
    );
  };

  const renderActions = (request) => {
    if (request.status === "pending") {
      return (
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            size="small"
            onClick={() => onSchedule(request)}>
            Schedule
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => onCancelRequest(request)}>
            Cancel
          </Button>
        </Stack>
      );
    }

    return null;
  };

  const renderGroupedRequests = () => {
    return Object.entries(groupedRequests).map(([userId, data]) => {
      const userInfo = groupBy === "donor" ? data.donorInfo : data.buyerInfo;

      return (
        <React.Fragment key={userId}>
          <TableRow>
            <TableCell colSpan={7}>
              <Typography variant="h6">
                {userInfo?.first_name} {userInfo?.last_name} - {userInfo?.email}
              </Typography>
              <Typography variant="body2">
                Address: {userInfo?.profiles?.address}
              </Typography>
              {groupBy === "donor" &&
                renderDonorAvailability(data.donorAvailability)}
            </TableCell>
          </TableRow>

          {groupBy === "buyer" &&
            Object.entries(data.cartGroups).map(([cartId, cartGroup]) => (
              <React.Fragment key={cartId}>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography variant="subtitle2" sx={{ pl: 2 }}>
                      Order Time:{" "}
                      {new Date(cartGroup.timestamp).toLocaleString()}
                    </Typography>
                  </TableCell>
                </TableRow>
                {cartGroup.requests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell sx={{ pl: 4 }}>{request.items.title}</TableCell>
                    <TableCell>{request.items.category}</TableCell>
                    <TableCell>{request.items.condition}</TableCell>
                    <TableCell>
                      {request.donor.first_name} {request.donor.last_name}
                    </TableCell>
                    <TableCell>{request.status}</TableCell>
                    <TableCell>{request.quantity}</TableCell>
                    <TableCell>{renderActions(request)}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}

          {groupBy === "donor" &&
            data.requests?.map((request) => (
              <TableRow key={request.id}>
                <TableCell>{request.items.title}</TableCell>
                <TableCell>{request.items.category}</TableCell>
                <TableCell>{request.items.condition}</TableCell>
                <TableCell>
                  {request.buyer.first_name} {request.buyer.last_name}
                </TableCell>
                <TableCell>{request.status}</TableCell>
                <TableCell>{request.quantity}</TableCell>
                <TableCell>{renderActions(request)}</TableCell>
              </TableRow>
            ))}
        </React.Fragment>
      );
    });
  };

  return (
    <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Condition</TableCell>
            <TableCell>{groupBy === "donor" ? "Buyer" : "Donor"}</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedRequests).length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No requests found
              </TableCell>
            </TableRow>
          ) : (
            renderGroupedRequests()
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RequestTable;
