import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Stack,
  Link,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [showError, setShowError] = useState(false); // Snackbar control

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setShowError(false);
    setLoading(true);

    try {
      // Authenticate the user
      const { data, error: authError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (authError) throw new Error(authError.message);

      if (!data.user) {
        throw new Error("User not found.");
      }

      const userId = data.user.id;

      // Fetch the user's role
      const { data: userInfo, error: userError } = await supabase
        .from("users")
        .select("role")
        .eq("id", userId)
        .single();

      if (userError) throw new Error(userError.message);

      const userRole = userInfo.role;
      // Redirect based on role
      if (userRole === "admin") {
        navigate("/admin/dashboard");
      } else if (userRole === "donor") {
        navigate("/donor/listings");
      } else if (userRole === "buyer") {
        navigate("/browse");
      } else {
        throw new Error("Invalid role. Contact support.");
      }
    } catch (err) {
      setError(err.message || "An error occurred during login.");
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowError(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "background.default",
        px: 2,
      }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          width: "100%",
          maxWidth: 400,
          borderRadius: 2,
        }}>
        <Typography
          variant="h4"
          component="h1"
          fontWeight="bold"
          sx={{ mb: 3, textAlign: "center" }}>
          Login
        </Typography>

        <form onSubmit={handleLogin}>
          <Stack spacing={3}>
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={loading} // Disable while loading
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Login"
              )}
            </Button>
          </Stack>
        </form>

        <Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
          Don’t have an account?{" "}
          <Link
            href="/signup"
            underline="hover"
            sx={{ color: "primary.main", fontWeight: "bold" }}>
            Sign up
          </Link>
        </Typography>
      </Paper>

      {/* Error Snackbar */}
      <Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error || "An error occurred. Please try again."}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoginPage;
