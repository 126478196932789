import React from "react";
import { Box, Modal, CardMedia, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const ImageModal = ({ open, onClose, image, alt }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(5px)", // Translucent background
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Darken background
      }}>
      <Box
        sx={{
          position: "relative",
          width: "90%",
          maxWidth: 800,
          p: 2,
          boxShadow: 24,
          borderRadius: 1,
        }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "grey.500",
          }}>
          <Close />
        </IconButton>
        <CardMedia
          component="img"
          image={image}
          alt={alt || "Enlarged image"}
          sx={{
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "90vh",
          }}
        />
      </Box>
    </Modal>
  );
};

export default ImageModal;
